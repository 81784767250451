import { ENotificationProcessType } from '@/features/notificationTasks/types';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as Yup from 'yup';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const colorRegExp = /^#(?:[0-9a-fA-F]{6}|[0-9a-fA-F]{8})$/;
export const externalIdRegExp = /^(?:[0-9a-zA-Z_-]*)$/;
export const newStoryRegExp = /^(?:new_story)$/;
export const newSlideRegExp = /^(?:new_slide)$/;

Yup.addMethod(Yup.string, 'regularTextInputValidation', function () {
  return this.required(`Необходимо заполнить поле`);
});

Yup.addMethod(Yup.string, 'colorInputValidation', function () {
  return this.required(`Необходимо заполнить поле`).test(
    'colorFieldTest',
    `Разрешенный формат цвета: #XXXXXX или #XXXXXXXX. Разрешенные символы: 0-9, a-f, A-F`,
    async (value) => {
      if (!value) {
        return true;
      }
      return colorRegExp.test(value);
    }
  );
});

Yup.addMethod(Yup.string, 'regularSelectComponentValidation', function () {
  return this.required(`Необходимо заполнить поле`);
});

Yup.addMethod(Yup.string, 'storyExternalIdValidation', function () {
  return this.required('Необходимо заполнить поле')
    .test(
      'validCharactersSetTest',
      `Разрешенные символы: 0-9, a-z, A-Z, _ , - (нельзя использовать кириллицу и пробелы)`,
      async (value) => {
        return externalIdRegExp.test(value);
      }
    )
    .test('newStoryTest', `Поле не может равняться строке "new_story"`, async (value) => {
      return !newStoryRegExp.test(value);
    })
    .max(30, 'Длина не должна превышать 30 символов');
});

Yup.addMethod(Yup.string, 'itemNameInputValidation', function () {
  return this.required(`Необходимо заполнить поле`).max(
    30,
    'Длина не должна превышать 30 символов'
  );
});

Yup.addMethod(Yup.string, 'storyTooltipTextInputValidation', function () {
  return this.required(`Необходимо заполнить поле`).max(
    30,
    'Длина не должна превышать 30 символов'
  );
});

Yup.addMethod(Yup.string, 'externalLinkInputValidation', function () {
  return this.required(`Необходимо заполнить поле`).max(
    2048,
    'Длина не должна превышать 2048 символов'
  );
});

Yup.addMethod(Yup.string, 'slideExternalIdValidation', function () {
  return this.required('Необходимо заполнить поле')
    .test(
      'validCharactersSetTest',
      `Разрешенные символы: 0-9, a-z, A-Z, _ , - (нельзя использовать кириллицу и пробелы)`,
      async (value) => {
        return externalIdRegExp.test(value);
      }
    )
    .test('newSlideTest', `Поле не может равняться строке "new_slide"`, async (value) => {
      return !newSlideRegExp.test(value);
    })
    .max(30, 'Длина не должна превышать 30 символов');
});

Yup.addMethod(Yup.string, 'slideTitleValueValidation', function () {
  return this.required('Необходимо заполнить поле').max(
    180,
    'Длина не должна превышать 180 символов'
  );
});

Yup.addMethod(Yup.string, 'slideDescriptionValueValidation', function () {
  return this.required('Необходимо заполнить поле').max(
    400,
    'Длина не должна превышать 400 символов'
  );
});

Yup.addMethod(Yup.string, 'itemButtonTextValueValidation', function () {
  return this.required('Необходимо заполнить поле').max(
    30,
    'Длина не должна превышать 30 символов'
  );
});

Yup.addMethod(Yup.string, 'itemActionTypeValidation', function () {
  return this.required('Необходимо заполнить поле');
});

Yup.addMethod(Yup.string, 'promotionNameValidation', function () {
  return this.required('Необходимо заполнить поле').max(
    180,
    'Длина не должна превышать 180 символов'
  );
});

Yup.addMethod(Yup.string, 'promotionTypeValidation', function () {
  return this.required('Необходимо заполнить поле');
});

Yup.addMethod(Yup.array, 'promotionCategoryIdsValidation', function () {
  return this.required('Необходимо заполнить поле').min(1, 'Необходимо заполнить поле');
});

Yup.addMethod(Yup.string, 'promotionDescriptionValidation', function () {
  return this.required('Необходимо заполнить поле').max(
    1000,
    'Длина не должна превышать 1000 символов'
  );
});

Yup.addMethod(Yup.string, 'promotionConditionValidation', function () {
  return this.required('Необходимо заполнить поле').max(
    5000,
    'Длина не должна превышать 5000 символов'
  );
});

Yup.addMethod(Yup.string, 'promotionRequirementsValidation', function () {
  return this.required('Необходимо заполнить поле').max(
    120,
    'Длина не должна превышать 120 символов'
  );
});

Yup.addMethod(Yup.string, 'promotionStartDatetimeValidation', function () {
  return this.required('Необходимо заполнить поле').test(
    'promotionFinishDatetimeValidationTest',
    `Дата начала должна быть раньше даты окончания акции`,
    (value, context) => {
      if (
        context.parent.promotionFinishDatetime &&
        !dayjs(value).isSameOrBefore(dayjs(context.parent.promotionFinishDatetime))
      ) {
        return false;
      }
      return true;
    }
  );
});

Yup.addMethod(Yup.string, 'promotionFinishDatetimeValidation', function () {
  return this.required('Необходимо заполнить поле').test(
    'promotionFinishDatetimeValidationTest',
    `Дата окончания должна быть позже даты начала акции`,
    (value, context) => {
      if (
        context.parent.promotionStartDatetime &&
        !dayjs(value).isSameOrAfter(dayjs(context.parent.promotionStartDatetime))
      ) {
        return false;
      }
      return true;
    }
  );
});

Yup.addMethod(Yup.string, 'promotionPublicationDatetimeValidation', function () {
  return this.required('Необходимо заполнить поле').test(
    'promotionPublicationDatetimeValidationTest',
    `Дата публикации должна быть позже или равняться дате начала, 
    дата публикации должна быть раньше или равняться дате окончания`,
    (value, context) => {
      if (
        (context.parent.promotionFinishDatetime &&
          !dayjs(value).isSameOrBefore(dayjs(context.parent.promotionFinishDatetime))) ||
        (context.parent.promotionStartDatetime &&
          !dayjs(value).isSameOrAfter(dayjs(context.parent.promotionStartDatetime)))
      ) {
        return false;
      }
      return true;
    }
  );
});

Yup.addMethod(Yup.string, 'promotionCollectionNameValidation', function () {
  return this.required('Необходимо заполнить поле').max(
    60,
    'Длина не должна превышать 60 символов'
  );
});

Yup.addMethod(Yup.array, 'promotionCollectionPromotionListValidation', function () {
  return this.required('Необходимо заполнить поле').min(
    1,
    'Необходимо добавить минимум одну акцию'
  );
});

Yup.addMethod(Yup.string, 'bannerTitleValueValidation', function () {
  return this.required('Необходимо заполнить поле').max(
    50,
    'Длина не должна превышать 50 символов'
  );
});

Yup.addMethod(Yup.string, 'bannerTextValueValidation', function () {
  return this.required('Необходимо заполнить поле').max(
    160,
    'Длина не должна превышать 160 символов'
  );
});

Yup.addMethod(Yup.string, 'notificationTitleAndDetailsTitleTextValueValidation', function () {
  return this.required('Необходимо заполнить поле').max(
    60,
    'Длина не должна превышать 60 символов'
  );
});

Yup.addMethod(
  Yup.string,
  'notificationDescriptionAndDetailsDescriptionValueValidation',
  function () {
    return this.required('Необходимо заполнить поле').max(
      170,
      'Длина не должна превышать 170 символов'
    );
  }
);

Yup.addMethod(Yup.string, 'notificationProcessScheduleDatetimeValidation', function () {
  return this.test(
    'notificationProcessScheduleDatetimeTest',
    `Необходимо заполнить поле`,
    (value, context) => {
      if (context.parent.notificationProcessType !== ENotificationProcessType.now) {
        return value && value.length !== 0;
      }
      return true;
    }
  );
});

export { Yup as default };
