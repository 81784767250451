import Button from '@mui/material/Button';
import { styled } from '@mui/system';

export const MainButton = styled(Button)`
  height: 40px;
  padding: 5px 20px;
  user-select: none;
  text-align: center;
  white-space: nowrap;
  touch-action: manipulation;
  font-weight: 600;
  color: #ffffff;

  border-radius: 10px;
  border: none;
  outline: none;
`;

MainButton.defaultProps = {
  // @ts-ignore: Unreachable code error
  color: 'buttonMain',
  variant: 'contained',
};
