import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, TextField } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Form, useFormik, FormikProvider } from 'formik';
import isEmpty from 'lodash.isempty';

import { TRootState, useAppDispatch } from '@/store';
import { SecondaryButton } from '@/shared/components/SecondaryButton';
import {
  resetActivePromotionCollectionId,
  resetPromotionCollection,
  setPromotionCollectionActivePage,
  setPromotionCollectionListImage,
  setPromotionCollectionMainImage,
  setPromotionCollectionName,
  setPromotionCollectionIsPublishedTruthy,
} from '@/features/promotions/redux/promotions.slice';
import {
  EPromotionCollectionStatusKey,
  EPromotionCollectionTabPages,
} from '@/features/promotions/types';
import { UploadFileWithTextField } from '@/shared/components/UploadFileWithTextField';
import { PromotionTable } from './components/PromotionTable';
import { MainButton } from '@/shared/components/MainButton';
import { AddPomotionModal } from './components/AddPromotionModal';
import {
  fetchPromotionCollectionItem,
  savePromotionCollectionItem,
  archivePromotionCollectionItem,
} from '@/features/promotions/redux/promotions.actions';
import Yup from '@/shared/validations';
import cloneDeep from 'lodash.clonedeep';

const validationSchema = Yup.object().shape({
  name: Yup.string().promotionCollectionNameValidation(),
  titleUrl: Yup.string().externalLinkInputValidation(),
  imageUrl: Yup.string().externalLinkInputValidation(),
  promotions: Yup.array().promotionCollectionPromotionListValidation(),
});

export const PromotionCollection: FC = () => {
  const dispatch = useAppDispatch();
  const promotionCollection = useSelector(
    (state: TRootState) => state.promotions.promotionCollection
  );
  const promotionCollectionActivePage = useSelector(
    (state: TRootState) => state.promotions.promotionCollectionActivePage
  );
  const activePromotionCollectionId = useSelector(
    (state: TRootState) => state.promotions.activePromotionCollectionId
  );
  const [isUIDisabled, setIsUIDisabled] = useState(false);
  const [serverErrors, setServerErrors] = useState<Record<string, string>>({});

  const formik = useFormik({
    initialValues: {
      name: '',
      titleUrl: '',
      imageUrl: '',
      promotions: [],
    },
    onSubmit: (values, actions) => {},
    validationSchema,
  });

  useEffect(() => {
    formik.setFieldValue('name', promotionCollection?.name || '');
    formik.setFieldValue('titleUrl', promotionCollection?.title_url || '');
    formik.setFieldValue('imageUrl', promotionCollection?.image_url || '');
    formik.setFieldValue('promotions', promotionCollection?.promotions || []);
  }, [promotionCollection]);

  useEffect(() => {
    if (
      !!activePromotionCollectionId &&
      promotionCollectionActivePage === EPromotionCollectionTabPages.existingPromotionCollection &&
      activePromotionCollectionId !== promotionCollection.id
    ) {
      dispatch(fetchPromotionCollectionItem(activePromotionCollectionId));
    }
  }, [activePromotionCollectionId, promotionCollectionActivePage, promotionCollection]);

  const [isAddPromotionModalOpen, setIsAddPromotionModalOpen] = useState(false);

  const isPromotionCollectionArchived = useMemo(() => {
    return promotionCollection.status.key === EPromotionCollectionStatusKey.archived;
  }, [promotionCollection]);

  const isPromotionCollectionPublishedOrReady = useMemo(() => {
    return (
      promotionCollection.status.key === EPromotionCollectionStatusKey.published ||
      promotionCollection.status.key === EPromotionCollectionStatusKey.ready
    );
  }, [promotionCollection]);

  const handleAddPromotionToCollectionBtnClick = () => {
    setIsAddPromotionModalOpen(true);
  };

  const handleAddPomotionModalClose = () => {
    setIsAddPromotionModalOpen(false);
  };

  const handleCancelBtnClick = () => {
    dispatch(resetPromotionCollection());
    dispatch(resetActivePromotionCollectionId());
    dispatch(
      setPromotionCollectionActivePage(EPromotionCollectionTabPages.promotionCollectionList)
    );
  };

  const handlePromotionCollectionNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPromotionCollectionName(event.target.value));
    formik.setFieldValue('name', event.target.value);
    const serverErrorsClone = cloneDeep(serverErrors);
    delete serverErrorsClone.name;
    setServerErrors(serverErrorsClone);
  };

  const handlePromotionCollectionListImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPromotionCollectionListImage(event.target.value));
    formik.setFieldValue('titleUrl', event.target.value);
    const serverErrorsClone = cloneDeep(serverErrors);
    delete serverErrorsClone.titleUrl;
    setServerErrors(serverErrorsClone);
  };
  const handlePromotionCollectionListImageUpload = (url: string) => {
    dispatch(setPromotionCollectionListImage(url));
    formik.setFieldValue('titleUrl', url);
    const serverErrorsClone = cloneDeep(serverErrors);
    delete serverErrorsClone.titleUrl;
    setServerErrors(serverErrorsClone);
  };

  const handlePromotionCollectionMainImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPromotionCollectionMainImage(event.target.value));
    formik.setFieldValue('imageUrl', event.target.value);
    const serverErrorsClone = cloneDeep(serverErrors);
    delete serverErrorsClone.imageUrl;
    setServerErrors(serverErrorsClone);
  };
  const handlePromotionCollectionMainImageUpload = (url: string) => {
    dispatch(setPromotionCollectionMainImage(url));
    formik.setFieldValue('imageUrl', url);
    const serverErrorsClone = cloneDeep(serverErrors);
    delete serverErrorsClone.imageUrl;
    setServerErrors(serverErrorsClone);
  };

  const savePromotionCollection = (props?: { isPublished: boolean }) => {
    const { isPublished } = props || {};
    if (isPublished) {
      dispatch(setPromotionCollectionIsPublishedTruthy());
    }
    const isNewPromotionCollection =
      promotionCollectionActivePage === EPromotionCollectionTabPages.newPromotionCollection;
    setIsUIDisabled(true);
    dispatch(savePromotionCollectionItem(isNewPromotionCollection))
      .unwrap()
      .then(() => {
        dispatch(
          setPromotionCollectionActivePage(EPromotionCollectionTabPages.promotionCollectionList)
        );
      })
      .catch((err) => {
        if (err.status === 422) {
          if (err?.data?.detail) {
            const errors: Record<string, string> = {};
            err.data.detail.forEach((detailItem: Record<string, any>) => {
              const loc = detailItem?.loc;
              const field = loc[loc.length - 1];

              if (field === 'name') {
                errors.name = detailItem.msg;
              } else if (field === 'image_url') {
                errors.imageUrl = detailItem.msg;
              } else if (field === 'title_url') {
                errors.titleUrl = detailItem.msg;
              }
            });
            if (!isEmpty(errors)) {
              formik.setErrors(errors);
              setServerErrors(errors);
            }
          }
        }

        setIsUIDisabled(false);
      });
  };

  const onSaveAsDraftBtnClick = () => {
    formik.setTouched({
      name: true,
      titleUrl: true,
      imageUrl: true,
      promotions: true,
    });
    formik.validateForm().then((errors) => {
      if (isEmpty(errors)) {
        savePromotionCollection();
      }
    });
  };

  const onSaveAsPublishedBtnClick = () => {
    formik.setTouched({
      name: true,
      titleUrl: true,
      imageUrl: true,
      promotions: true,
    });
    formik.validateForm().then((errors) => {
      if (isEmpty(errors)) {
        savePromotionCollection({ isPublished: true });
      }
    });
  };

  const onSaveAsArchiveBtnClick = () => {
    setIsUIDisabled(true);
    dispatch(archivePromotionCollectionItem())
      .unwrap()
      .then(() => {
        dispatch(
          setPromotionCollectionActivePage(EPromotionCollectionTabPages.promotionCollectionList)
        );
      })
      .catch(() => {
        setIsUIDisabled(false);
      });
  };

  const renderSaveBtns = () => {
    const isShowSaveAsArchiveBtn =
      promotionCollectionActivePage === EPromotionCollectionTabPages.existingPromotionCollection &&
      (promotionCollection.status?.key === EPromotionCollectionStatusKey.published ||
        promotionCollection.status?.key === EPromotionCollectionStatusKey.ready);
    const isShowSaveAsDraftBtn =
      promotionCollectionActivePage === EPromotionCollectionTabPages.newPromotionCollection ||
      (promotionCollectionActivePage === EPromotionCollectionTabPages.existingPromotionCollection &&
        promotionCollection.status?.key === EPromotionCollectionStatusKey.draft);
    const isShowSaveAsPublishedBtn =
      promotionCollectionActivePage === EPromotionCollectionTabPages.newPromotionCollection ||
      (promotionCollectionActivePage === EPromotionCollectionTabPages.existingPromotionCollection &&
        (promotionCollection.status?.key === EPromotionCollectionStatusKey.draft ||
          promotionCollection.status?.key === EPromotionCollectionStatusKey.ready ||
          promotionCollection.status?.key === EPromotionCollectionStatusKey.published));

    return (
      <>
        {isShowSaveAsDraftBtn && (
          <SecondaryButton
            sx={{ width: '210px', mb: 2, height: '40px' }}
            size="small"
            onClick={onSaveAsDraftBtnClick}
            disabled={isUIDisabled}
          >
            Сохранить как черновик
          </SecondaryButton>
        )}
        {isShowSaveAsPublishedBtn && (
          <MainButton
            sx={{ width: '150px', mb: 2 }}
            disabled={isUIDisabled}
            onClick={onSaveAsPublishedBtnClick}
          >
            Опубликовать
          </MainButton>
        )}
        {isShowSaveAsArchiveBtn && (
          <SecondaryButton
            sx={{ width: '100px', height: '40px' }}
            size="small"
            onClick={onSaveAsArchiveBtnClick}
            disabled={isUIDisabled}
          >
            В архив
          </SecondaryButton>
        )}
      </>
    );
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box sx={{ p: 3, minWidth: '540px' }}>
          <TextField
            sx={{ mb: formik.touched.name && !!formik.errors.name ? '7px' : 2, width: '300px' }}
            label="Название"
            type="text"
            size="small"
            inputProps={{
              readOnly: isPromotionCollectionArchived || isPromotionCollectionPublishedOrReady,
            }}
            disabled={isUIDisabled}
            name="name"
            value={formik.values.name}
            onChange={handlePromotionCollectionNameChange}
            error={(formik.touched.name && !!formik.errors.name) || !!serverErrors.name}
            helperText={(formik.touched.name && formik.errors.name) || serverErrors.name}
          />
          <UploadFileWithTextField
            label="Изображение для списка"
            type="text"
            size="small"
            sx={{
              mb: formik.touched.titleUrl && !!formik.errors.titleUrl ? '7px' : 2,
              width: '300px',
            }}
            readOnly={isPromotionCollectionArchived || isPromotionCollectionPublishedOrReady}
            disabled={isUIDisabled}
            name="titleUrl"
            value={formik.values.titleUrl}
            onChange={handlePromotionCollectionListImageChange}
            handleFileUpload={handlePromotionCollectionListImageUpload}
            error={(formik.touched.titleUrl && !!formik.errors.titleUrl) || !!serverErrors.titleUrl}
            helperText={
              (formik.touched.titleUrl && formik.errors.titleUrl) || serverErrors.titleUrl
            }
          />
          <UploadFileWithTextField
            label="Основное изображение"
            type="text"
            size="small"
            sx={{
              mb: formik.touched.imageUrl && !!formik.errors.imageUrl ? '7px' : 2,
              width: '300px',
            }}
            readOnly={isPromotionCollectionArchived || isPromotionCollectionPublishedOrReady}
            disabled={isUIDisabled}
            name="imageUrl"
            value={formik.values.imageUrl}
            onChange={handlePromotionCollectionMainImageChange}
            handleFileUpload={handlePromotionCollectionMainImageUpload}
            error={(formik.touched.imageUrl && !!formik.errors.imageUrl) || !!serverErrors.imageUrl}
            helperText={
              (formik.touched.imageUrl && formik.errors.imageUrl) || serverErrors.imageUrl
            }
          />
          <PromotionTable
            isPromotionCollectionArchived={isPromotionCollectionArchived}
            promotionItems={promotionCollection?.promotions}
            error={formik.touched.promotions && !!formik.errors.promotions}
            helperText={formik.touched.promotions && formik.errors.promotions}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
              {!isPromotionCollectionArchived && (
                <SecondaryButton
                  sx={{ width: '250px', mb: 2 }}
                  size="small"
                  startIcon={<AddRoundedIcon style={{ fontSize: 30, marginLeft: '-7px' }} />}
                  onClick={handleAddPromotionToCollectionBtnClick}
                  disabled={isUIDisabled}
                >
                  Добавить акции в подборку
                </SecondaryButton>
              )}
              <SecondaryButton
                sx={{ width: '100px', height: '40px' }}
                size="small"
                disabled={isUIDisabled}
                onClick={handleCancelBtnClick}
              >
                Отмена
              </SecondaryButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>{renderSaveBtns()}</Box>
          </Box>
        </Box>
        <AddPomotionModal open={isAddPromotionModalOpen} onClose={handleAddPomotionModalClose} />
      </Form>
    </FormikProvider>
  );
};
