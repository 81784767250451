import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

import { MainButton } from '@/shared/components/MainButton';
import { SecondaryButton } from '@/shared/components/SecondaryButton';
import { PromotionTable } from './components/PromotionTable';
import { SearchInput } from '@/shared/components/SearchInput';
import { TRootState, useAppDispatch } from '@/store';
import { mergePromotionCollectionPromotions } from '@/features/promotions/redux/promotions.slice';
import { IPromotionCollectionItemPromotion } from '@/features/promotions/types';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  height: 600,
  minHeight: '390px',
  maxHeight: 'calc(100vh - 40px)',
};

interface IBasicModalProps {
  open: boolean;
  onClose?: () => void;
}

export const AddPomotionModal: FC<IBasicModalProps> = ({
  open: openProp,
  onClose: onCloseProp,
}) => {
  const dispatch = useAppDispatch();
  const promotionItems = useSelector((state: TRootState) => state.promotions.promotionItems);
  const [promotionSearch, setPromotionSearch] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedPromotionIds, setSelectedPromotionIds] = useState([]);

  const isAddPromotionsBtnDisabled = useMemo(() => {
    return !selectedPromotionIds || selectedPromotionIds.length === 0;
  }, [selectedPromotionIds]);

  useEffect(() => {
    if (openProp) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [openProp]);

  const handleConfirmBtnClick = () => {
    handleClose();
    onCloseProp?.();
    const selectedPromotions = promotionItems.filter((promotion) => {
      return selectedPromotionIds?.indexOf(promotion.id) > -1;
    });
    dispatch(mergePromotionCollectionPromotions(selectedPromotions));
  };

  const handleCancelBtnClick = () => {
    handleClose();
    onCloseProp?.();
  };

  const handleBackdropClick = () => {
    handleClose();
    onCloseProp?.();
  };

  const handlePromotionSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPromotionSearch(event.target.value);
  };

  const handleSelectedPromotionIdsChange = (itemsIds: number[]) => {
    setSelectedPromotionIds(itemsIds);
  };

  return (
    <Modal
      aria-labelledby="add-promotion-modal-title"
      open={open}
      onClose={handleBackdropClick}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{
        '& .MuiBackdrop-root.MuiModal-backdrop': {
          backgroundColor: 'rgba(255, 255, 255, 0.65)',
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="add-promotion-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            Выберите акции для добавления к подборке
          </Typography>
          <SearchInput
            label="Название"
            sx={{ maxWidth: '260px', mb: 2 }}
            value={promotionSearch}
            onChange={handlePromotionSearchChange}
          />
          <PromotionTable
            search={promotionSearch}
            onSelectedPromotionIdsChange={handleSelectedPromotionIdsChange}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <MainButton
              sx={{ mr: 2 }}
              disabled={isAddPromotionsBtnDisabled}
              onClick={handleConfirmBtnClick}
            >
              Добавить
            </MainButton>
            <SecondaryButton onClick={handleCancelBtnClick}>Отмена</SecondaryButton>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
