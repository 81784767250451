import { ChangeEvent, KeyboardEvent, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Hidden, SelectChangeEvent, Stack } from '@mui/material';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import cloneDeep from 'lodash.clonedeep';

import { CustomDatePicker } from '@/shared/components/CustomDatePicker';
import { SearchInput } from '@/shared/components/SearchInput';
import { CustomSelect } from '@/shared/components/CustomSelect';
import {
  EPromotionItemActionType,
  EPromotionMassActionActionType,
  EPromotionStatusKey,
  EPromotionStatusName,
  EPromotionTabPages,
} from '@/features/promotions/types';
import { SecondaryButton } from '@/shared/components/SecondaryButton';
import { StartButton } from './elements';
import { PromotionTable } from './components/PromotionTable';
import { TRootState, useAppDispatch } from '@/store';
import {
  setFilterPromotionsByCategory,
  setFilterPromotionsByFinishDate,
  setFilterPromotionsByName,
  setFilterPromotionsByStartDate,
  setFilterPromotionsByType,
  setPromotionAtivePage,
} from '@/features/promotions/redux/promotions.slice';
import {
  promotionSatusOptionValues,
  promotionStatusOptionTexts,
} from '@/features/promotions/const';
import {
  fetchPromotionItems,
  fetchPromotionVisualizationData,
  updatePromotionMassStatus,
} from '@/features/promotions/redux/promotions.actions';
import { usePromotionTypesSelectData } from '../hooks/usePromotionTypesSelectData';
import { usePromotionCategoriesSelectData } from '../hooks/usePromotionCategoriesSelectData';

export const PromotionList = () => {
  const dispatch = useAppDispatch();
  const selectedPromotionIds = useSelector(
    (state: TRootState) => state.promotions.selectedPromotionIds
  );
  const { promotionTypeOptionTexts, promotionTypeOptionValues } = usePromotionTypesSelectData();
  const { promotionCategoriesOptionTexts, promotionCategoriesOptionValues } =
    usePromotionCategoriesSelectData();
  const promotionItems = useSelector((state: TRootState) => state.promotions.promotionItems);
  const promotionName = useSelector((state: TRootState) => state.promotions.promotionFilters.name);
  const promotionStatusKey = useSelector(
    (state: TRootState) => state.promotions.promotionFilters.statusKey
  );
  const promotionTypeId = useSelector(
    (state: TRootState) => state.promotions.promotionFilters.typeId
  );
  const promotionCategoryId = useSelector(
    (state: TRootState) => state.promotions.promotionFilters.categoryId
  );
  const promotionStartDate = useSelector(
    (state: TRootState) => state.promotions.promotionFilters.startDate
  );
  const promotionFinishDate = useSelector(
    (state: TRootState) => state.promotions.promotionFilters.finishDate
  );

  const promotionMassSuspendActionBtnDisabled = useMemo(() => {
    const isNonePromotionSelected = selectedPromotionIds?.length === 0;
    const isSomeActivePromotionSelected = promotionItems.some((promotion) => {
      if (
        selectedPromotionIds.includes(promotion.id) &&
        promotion.status.key !== EPromotionStatusKey.suspended
      ) {
        return true;
      }

      return false;
    });

    return isNonePromotionSelected || !isSomeActivePromotionSelected;
  }, [selectedPromotionIds, promotionItems]);

  const promotionMassReactivateActionBtnDisabled = useMemo(() => {
    const isNonePromotionSelected = selectedPromotionIds?.length === 0;
    const isSomeSuspendedPromotionSelected = promotionItems.some((promotion) => {
      if (
        selectedPromotionIds.includes(promotion.id) &&
        promotion.status.key === EPromotionStatusKey.suspended
      ) {
        return true;
      }

      return false;
    });

    return isNonePromotionSelected || !isSomeSuspendedPromotionSelected;
  }, [selectedPromotionIds, promotionItems]);

  useEffect(() => {
    dispatch(fetchPromotionItems());
  }, []);

  const handleNewPromotionClick = () => {
    dispatch(setPromotionAtivePage(EPromotionTabPages.newPromotion));
  };

  const handlePromotionSuspendBtnClick = () => {
    dispatch(
      updatePromotionMassStatus({
        promotionMassActionType: EPromotionMassActionActionType.suspend,
        promotionIds: selectedPromotionIds,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(fetchPromotionItems());
      });
  };

  const handlePromotionReactivateBtnClick = () => {
    dispatch(
      updatePromotionMassStatus({
        promotionMassActionType: EPromotionMassActionActionType.reactivate,
        promotionIds: selectedPromotionIds,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(fetchPromotionItems());
      });
  };

  const handleNameFilterChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setFilterPromotionsByName(event.target.value));
  };

  const handleNameFilterKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(fetchPromotionItems());
    }
  };

  const handlePromotionTypeFilterChange = (value: string) => {
    const typeId = !value ? value : Number(value);
    dispatch(setFilterPromotionsByType(typeId));
    dispatch(fetchPromotionItems());
  };

  const handlePromotionStartDateChange = (date: string) => {
    dispatch(setFilterPromotionsByStartDate(date));
    dispatch(fetchPromotionItems());
  };

  const handlePromotionCategoryFilterChange = (value: string) => {
    const categoryId = !value ? value : Number(value);
    dispatch(setFilterPromotionsByCategory(categoryId));
    dispatch(fetchPromotionItems());
  };

  const handlePromotionFinishDateChange = (date: string) => {
    dispatch(setFilterPromotionsByFinishDate(date));
    dispatch(fetchPromotionItems());
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
          <Stack mr={{ lg: 0, xl: 2 }}>
            <Stack spacing={2} direction="row" sx={{ marginBottom: '12px' }}>
              <SearchInput
                label="Название"
                sx={{ maxWidth: '260px' }}
                value={promotionName}
                onChange={handleNameFilterChange}
                onKeyUp={handleNameFilterKeyUp}
              />
              <CustomSelect
                label="Тип акции"
                optionValues={promotionTypeOptionValues}
                optionTexts={promotionTypeOptionTexts}
                sx={{ maxWidth: '260px' }}
                value={promotionTypeId?.toString()}
                onChange={handlePromotionTypeFilterChange}
              />
              <CustomDatePicker
                label="Дата старта"
                value={promotionStartDate}
                onChange={handlePromotionStartDateChange}
              />
            </Stack>
            <Stack spacing={2} direction="row" sx={{ marginBottom: '12px' }}>
              <CustomSelect
                label="Статус"
                optionValues={promotionSatusOptionValues}
                optionTexts={promotionStatusOptionTexts}
                sx={{ maxWidth: '260px' }}
                disabled
                value={promotionStatusKey}
              />
              <CustomSelect
                label="Категория"
                optionValues={promotionCategoriesOptionValues}
                optionTexts={promotionCategoriesOptionTexts}
                sx={{ maxWidth: '260px' }}
                value={promotionCategoryId?.toString()}
                onChange={handlePromotionCategoryFilterChange}
              />
              <CustomDatePicker
                label="Дата окончания"
                value={promotionFinishDate}
                onChange={handlePromotionFinishDateChange}
              />
            </Stack>
          </Stack>
        </Grid>
        <Hidden xlDown>
          <Grid item xl={4}>
            <Stack direction="column" sx={{ marginBottom: 2, alignItems: 'flex-end' }}>
              <SecondaryButton
                sx={{ width: '160px', marginBottom: '66px' }}
                startIcon={<AddRoundedIcon style={{ fontSize: 30 }} />}
                size="large"
                onClick={handleNewPromotionClick}
              >
                Новая акция
              </SecondaryButton>
              <Stack spacing={2} direction="row">
                <SecondaryButton
                  startIcon={<SquareRoundedIcon />}
                  disabled={promotionMassSuspendActionBtnDisabled}
                  onClick={handlePromotionSuspendBtnClick}
                >
                  Остановить
                </SecondaryButton>
                <StartButton
                  startIcon={<PlayArrowRoundedIcon style={{ fontSize: 30 }} />}
                  disabled={promotionMassReactivateActionBtnDisabled}
                  onClick={handlePromotionReactivateBtnClick}
                >
                  Запустить
                </StartButton>
              </Stack>
            </Stack>
          </Grid>
        </Hidden>
        <Hidden xlUp>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack spacing={2} direction="row" sx={{ marginBottom: 2 }}>
              <SecondaryButton
                startIcon={<SquareRoundedIcon />}
                onClick={handlePromotionSuspendBtnClick}
                disabled={promotionMassSuspendActionBtnDisabled}
              >
                Остановить
              </SecondaryButton>
              <StartButton
                startIcon={<PlayArrowRoundedIcon style={{ fontSize: 30 }} />}
                onClick={handlePromotionReactivateBtnClick}
                disabled={promotionMassReactivateActionBtnDisabled}
              >
                Запустить
              </StartButton>
              <SecondaryButton
                sx={{ width: '160px' }}
                startIcon={<AddRoundedIcon style={{ fontSize: 30 }} />}
                onClick={handleNewPromotionClick}
              >
                Новая акция
              </SecondaryButton>
            </Stack>
          </Grid>
        </Hidden>
      </Grid>
      <PromotionTable promotionItems={promotionItems} />
    </>
  );
};
