import Button from '@mui/material/Button';
import { styled } from '@mui/system';

export const SecondaryButton = styled(Button)`
  position: relative;
  height: ${({ size }) => !size && '40px'};
  padding: ${({ size }) => !size && '5px 20px'};
  cursor: pointer;
  user-select: none;
  text-align: center;
  white-space: nowrap;
  touch-action: manipulation;
  font-weight: 600;
  color: #ffffff;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.55);
  outline: none;
`;
